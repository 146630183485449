.hero-container {
  background: url('/src/images/img-home.jpeg') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -10px;
}

.hero-container > h3 {
  color: #fff;
  font-size: 32px;
}

.social-icon-link {
  color: #fff;
  font-size: 30px;
}

.social-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 220px;
  margin-top: 15px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > h3 {
    font-size: 30px;
  }
}
