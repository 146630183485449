.cards {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.cards-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards-wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards-items {
  margin-bottom: 24px;
}

.cards-item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards-item-link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards-item-pic-wrap {
  position: relative;
  width: 430px;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards-item-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards-item-img:hover {
  transform: scale(1.1);
}

.cards-item-info {
  padding: 20px 30px 30px;
}

.cards-item-text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1024px) {
  .cards-items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards-item {
    margin-bottom: 2rem;
  }
}
